<template lang="">
<div>
    <v-container class="pa-2 d-flex justify-center">
        <v-col lg="4" sm="6" style="padding: 0px;">
            <v-row class="d-flex justify-center align-center">
                <v-img src="@/assets/img/checkmong/simple_azq/logo-Eisai_03.png" contain max-height="100px" max-width="150px"></v-img>
                <v-img src="@/assets/img/checkmong/simple_azq/02-Eisai-logo1.png" contain max-height="50px" max-width="70px"></v-img>
                <v-img src="@/assets/img/checkmong/simple_azq/logo3.png" contain max-height="50px" max-width="70px"></v-img>
            </v-row>
            <v-container class="">
                <br>
                <v-row class="d-flex justify-center" style="text-align: center">
                    <h2 class="text-pink">แบบคำถามประเมินความเสี่ยงสมองเสื่อม</h2>
                    <br>
                    <br>
                    <br>
                    <v-card-text class="pb-0" style="text-align: justify;">
                        <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
                            <v-text-field type="text" v-model="name" label="ชื่อ-สกุล*" :rules="nameRules" solo flat required height="56px" background-color="white" > </v-text-field>
                            <v-text-field type="tel" v-model="age" label="อายุ*" :rules="nameRules" solo flat required height="56px" background-color="white"></v-text-field>
                            <v-text-field type="tel" v-model="phone" label="เบอร์โทรศัพท์" solo flat height="56px" background-color="white"></v-text-field>
                        </v-form>
                    </v-card-text>
                </v-row>
                <br>
                <br>
                <br>
                <v-row class="d-flex justify-center">
                    <v-btn elevation="2" x-large block rounded class="btn-pink" @click="goNext" :disabled="!is_btn_active">
                        <h2 style="font-weight: bold; letter-spacing: -1px; ">เริ่มทำแบบทดสอบ</h2>
                    </v-btn>
                </v-row>

            </v-container>

        </v-col>
    </v-container>
</div>
</template>

    
<script>
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
export default {
    data() {
        return {
            logo: require('@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png'),
            nameRules: [
                v => !!v || "โปรดกรอกข้อมูล",

            ],
            name: '',
            age: null,
            phone: '',
            valid: true,
            is_btn_active: false,
        };
    },
    watch: {
        name(val) {
            if (val && this.age) {
                this.is_btn_active = true;
            } else {
                this.is_btn_active = false;
            }
        },
        age(val) {
            if (val && this.name) {
                this.is_btn_active = true;
            } else {
                this.is_btn_active = false;
            }
        },
    },

    methods: {
        goNext() {
            const simple_azq_ans = this.$store.state.simple_azq_ans
            this.$store.commit('setSimpleAzqAns', {
                    ...simple_azq_ans,
                    'full_name': this.name,
                    'age': this.age,
                    'phonenumber': this.phone,


                });
            this.$router.push('/simple_azq_1')
        }
    },
    beforeCreate() {

    },
    mounted() {

    },
    created() {},
    beforeUpdate() {

    },
    updated() {
        //console.log('updated')

    }

};
</script>

    
<style scoped>
.footer-sm {
    position: absolute;
    bottom: 0;
    left: 0;
}

.btn-pink {
    background: #AE1B77 !important;
    color: white !important;
    box-shadow: 4px 1px 4px rgba(201, 200, 200, 0.25) !important;
    font-weight: bold !important;
}

.text-pink {
    color: #601550 !important;
}

.text-size {
    font-size: 18px;
}


</style>
